@mixin oyan-link-receivers-form-dialog-theme($theme) {
  .link-receivers {
    &__form {
      margin-bottom: 0 !important;
      background: #F8F8F8;

      .mat-mdc-form-field-infix {
        width: auto;
      }
    }

    .mat-toolbar--gray {
      background: #f6f7fa !important;
    }
  }
}
