@mixin oyan-site-info-theme($theme) {
  .site-info {
    &__image {
      background: #e9eff7;
    }

    .mat-mdc-form-field-infix {
      width: 100%;
    }
  }
}
