@use '@angular/material' as mat;
@use 'sass:map';

@mixin oyan-tab-nav-bar-theme($theme) {
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);

  .tab-nav-bar__list.mat-mdc-tab-nav-bar {
    position: sticky;
    left: 0;
    right: 0;
    top: 56px;
    bottom: 0;
    z-index: 10;

    &.mat-mdc-tab-header {
      border-bottom: 1px solid #ADADAD;
      height: 48px;
      background: #f8f8f8;
    }

    &.mat-primary .mat-ink-bar {
      height: 3px;
    }

    &.centered .mat-mdc-tab-links {
      display: flex;
      place-content: center;
      align-items: center;
      flex-direction: row;
      box-sizing: border-box;
    }
  }
}
