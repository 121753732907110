@mixin oyan-site-card-theme($theme) {
  .site-card {
    color: #394c5a;

    &__image {
      background: #e9eff7;
    }

    &__item {
      color: #c8c8c8;
    }

    &__value {
      color: #394c5a;
    }

    &:hover .mat-mdc-icon-button {
      display: block;
    }

    .mat-mdc-icon-button {
      display: none;

      &[aria-expanded='true'] {
        display: block;
      }
    }
  }
}
